import Constants from "../../../../Constants";
import TrainerHome from "../trainer_feature_component/trainer_home_component/TrainerHome";
import JobSupporterHome from "../job_supporter_feature_component/job_supporter_home_component/JobSupporterHome";
import InterviewSupporterHome from "../interview_supporter_feature_component/interview_supporter_home_component/InterviewSupporterHome";

function ExponentHome() {

    const selectedExponentType = localStorage.getItem("breezeSelectedExponentType");

    const trainerFlow = [
        "1) Trainer can create a training for selected course in Create Training menu.",
        "2) Once trainer will create a training, Admin will approve or reject the training based on requirement.",
        "3) If training will be rejected, Admin will provide proper description regarding rejection.",
        "4) And trainer can update the rejected training according to the given description.",
        "5) If training is approved, Then training will be available for candidates to enroll.",
        "6) Training will be automatically start by course start date.",
        "7) Trainer can join the training session at scheduled time of started training.",
        "8) Admin can change course start date based on enrollments.",
        "9) Admin can change couse end date.",
        "10) Training will be automatically complete by course end date."
    ];

    const intervieweSupporterFlow = [
        "1) Based on Interviewer technical stack and time slots, the interviewer can Create Interview Support.",
        "2) Once the interviewer create interview support,Admin will approve or reject the interview support based on the requirement.",
        "3) If interview support is rejected, Admin will provide a proper description regarding the rejection.",
        "4) And interviewer can update the rejected interview support according to the given description.",
        "5) If the interview support is approved, then the interview support will be available for candidates to book.",
        "6) And interviewer can update the approved interview support.",
        "7) If updated interview support is approved by admin, candidates can see the updated interview support data for booking.",
        "8) If updated interview support is rejected by admin, candidates can see the old interview support data for booking.",
        "9) Candidates select date and choose the interviewer's available time slots to book Interview Support.",
        "10) And interviewer can see Candidate bookings in interview support bookings menu.",
        "11) And interviewer can join the interview support session at the scheduled time in schedule bookings menu.",
        "12) And interview support will automatically complete on the next day of booking date.",
        "13) And interviewer can see his completed interview supports in Completed Bookings menu."
    ];

    const jobSupporterFlow = [
        "1) Based on Jobsupporter technical stack and time slots, the jobsupporter can create Job Support.",
        "2) Once the Jobsupporter creates Job Support, Admin will approve or reject the Job Support based on the requirement.",
        "3) If Job Support is rejected, Admin will provide a proper description regarding the rejection.",     
        "4) The jobsupporter can update the rejected Job Support according to the given description.",
        "5) If the Job Support is approved, then the Job Support will be available for candidates to book.",
        "6) The jobsupporter can update the approved Job Support.",
        "7) If the updated Job Support is approved by Admin, candidates can see the updated Job Support data for booking.",
        "8) If the updated Job Support is rejected by Admin, candidates can see the old Job Support data for booking.",
        "9) Candidates select Job Support start date and choose the available time slots to book Job Support.",
        "11) Job Support end date is automatically updated to after 29 days from the Job Support start date.",
        "13) The jobsupporter can see candidate bookings in the Job Support bookings menu.",
        "14) The jobsupporter can join the Job Support session at the scheduled time in the schedule bookings menu.",
        "15) Job Support will automatically complete on the next day of the Job Support booking end date.",
        "16) The jobsupporter can see his completed Job Supports in the Completed Bookings menu."
    ];

    return (
        <div>
            {selectedExponentType === Constants.TRAINER && (
                <TrainerHome trainerFlow={trainerFlow} />
            )}
            {selectedExponentType === Constants.JOB_SUPPORTER && (
                <JobSupporterHome jobSupporterFlow={jobSupporterFlow} />
            )}
            {selectedExponentType === Constants.INTERVIEW_SUPPORTER && (
                <InterviewSupporterHome interviewSupporterFlow={intervieweSupporterFlow} />
            )}
        </div>
    );
}

export default ExponentHome;