import Constants from "../../Constants";
import './Home.css';
import AdminHome from '../feature_component/admin_feature_component/admin_home_component/AdminHome';
import CandidateHome from '../feature_component/candidate_feature_component/training/candidate_home_component/CandidateHome';
import ExponentHome from "./exponent_feature_component/exponent_home_component/ExponentHome";

function Home() {

    const userType = localStorage.getItem("breezeUserType");

    const candidateTrainingFlow = [
        "1) Candidate can search training based on provided technologies.",
        "2) Candidate can enroll the training, If he intrested.",
        "3) Once Candidate will enroll the training, Admin will confirm or withdraw by contacting the candidate.",
        "4) If Candidate enrollment confirmed, Candidate can join training sessions in Scheduled Trainings menu if training is started.",
        "5) Admin can change course start date and end date of training, If required.",
        "6) Training will be automatically complete by course end date.",
        "7) Once training is completed, Candidates can rate the training in Completed Trainings menu.",
    ];

    const candidateInterviewSupportFlow = [
        "1) Candidate can book the Interview Support based on their requirements.",
        "2) If the interview support booking is withdrawn by the admin, Then Booking will be Cancelled",
        "3) If the interview support booking is approved by the admin, Then Candidate is eligible to take interview support from the interviewer.",
        "4) If the candidate interview is Cancelled at the scheduled time and date, Then candidate need to cancel the interview in Interview Support Bookings menu.",
        "5) If the candidate's interview is rescheduled, Then candidate can reschedule their interview support booking in Interview Support Bookings menu.",
        "7) Candidate can join the interview support session at the scheduled time and date in the Scheduled Bookings menu.",
        "8) The interview support will automatically complete on the next day of the booking date.",
        "9) Candidate can see their completed interview supports in the Completed Bookings menu and Candidate can rate their Interview Support."
    ];

    const candidateJobSupportFlow = [
        "1) Candidate can book the Job Support based on their requirements.",
        "2) If the candidate by mistake chooses the wrong job support start date, booking slots, or technical stack, they can cancel " +
        "the job support booking before admin approval.",
        "3) If the candidate by mistake chooses the wrong job support start date and booking slots, they can reschedule the job support booking:",
        "4) Before the job support starts, candidates can change the start date and booking slots.",
        "5) After the job support starts, candidates can only change the booking slots.",
        "6) If the candidate does not like the job supporter's support, they need to stop the job support booking in the Job Support Bookings menu.",
        "7) If the candidate does not like the job supporter's support and they want to assign another job supporter, they can do so in the Job Support Bookings menu.",
        "8) If the candidate wants to continue the job support, they can click the continue option in the Job Support Bookings menu.",
        "9) Candidates can see their job supports in the Candidate Support Bookings menu.",
        "10) Candidates can join the job support session at the scheduled time in the Scheduled Bookings menu.",
        "11) The job support will automatically complete on the next day after the job support booking end date.",
        "12) Candidates can see their completed job supports in the Candidate Support Completed Job Support menu."
    ];

    return (
        <div>
            { userType === Constants.ADMIN && (
                <AdminHome />
            )}
            { userType === Constants.EXPONENT && (
                <ExponentHome />
            )}
            { userType === Constants.CANDIDATE && (
                <CandidateHome candidateTrainingFlow={candidateTrainingFlow} candidateInterviewSupportFlow={candidateInterviewSupportFlow} candidateJobSupportFlow={candidateJobSupportFlow} />
            )}
        </div>        
    );

}

export default Home;